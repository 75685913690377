<template>
  <div id="profile-setting">
    <h5>
      {{ t('title.Setting') }}
    </h5>
    <b-card class="mb-0">
      <!-- <b-col
        cols="12"
        class="mt-1"
      > -->
      <div class="d-flex align-items-center mb-3">
        <feather-icon
          icon="LinkIcon"
          size="12"
        />
        <h6 class="mb-0 ml-75">
          Social Connections
        </h6>
      </div>
      <b-row>
        <b-col cols="8">
          <span class="text-success">
            Your Line or Email
          </span><br>
          <small>Line: {{ userData.fullName || userData.username }}</small>
        </b-col>
        <b-col
          cols="4"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            size="sm"
            @click="confirmDisconnect"
          >
            Disconnect
          </b-button>
        </b-col>
        <!-- <b-col cols="12">
          <hr>
        </b-col>
        <b-col cols="8">
          Allow Line Notifications
        </b-col>
        <b-col
          cols="4"
          class="text-right"
        >
          <b-form-checkbox
            v-model="allowNotification"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="checkAllowNotification"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>
        </b-col> -->
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      accountData: JSON.parse(localStorage.getItem('accountData')),
      pin: '',
      allowNotification: false,
    }
  },
  methods: {
    showAlert(Title, Text, Icon) {
      this.$swal({
        title: Title,
        text: Text,
        icon: Icon,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    confirmDisconnect() {
      this.$swal({
        title: 'Disconnect Line or Email?\n\nPin or Password',
        input: 'password',
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          // console.log('Disconnect', result.value)
          if (result.value !== '' && result.value !== null && result.value !== undefined) {
            this.pin = result.value
            this.disconnectLine()
          }
        }
      })
    },
    disconnectLine() {
      console.log('Disconnect Line')
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/DeConnectAccount',
        method: 'POST',
        data: {
          accountID: this.accountData.accountID,
          customerID: this.accountData.customerID,
          email: '',
          pin: this.pin,
        },
      }).then(({ data }) => {
        this.showAlert('Success!', data, 'success')
        this.logout()
      }).catch(error => {
        console.log('disconnectLine', error)
        this.showAlert('Error!', error, 'error')
      })
    },
    checkAllowNotification() {
      if (this.allowNotification) {
        // Check Line Friend
        useJwt.setToken(this.userData.access_token)
        this.$http({
          url: 'https://api.line.me/friendship/v1/status',
          method: 'GET',
          data: {},
        }).then(({ data }) => {
          console.log('Line Status', data)
        }).catch(error => {
          console.log('checkAllowNotification', error)
        })
      } else {
        console.log('Close Notification')
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Remove patientData from localStorage
      localStorage.removeItem('patientData')
      localStorage.removeItem('patientTempData')
      localStorage.removeItem('patientImage')

      // Remove accountData from LocalStorage
      localStorage.removeItem('accountData')

      // Remove all data from localStorage
      localStorage.clear()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
